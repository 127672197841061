import {
  Flex,
  Paper,
  Image,
  Title,
  Stack,
  Button,
  Box,
  Text,
  Container,
  Modal,
  LoadingOverlay,
} from "@mantine/core"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import React, { useEffect } from "react"
//@ts-ignore
import * as MyPOSEmbedded from "mypos-embedded-checkout"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { useGetUser } from "../../api/user/use-get-user"
import { usePostUserPayment } from "../../api/user/use-post-user-payment"
import {
  setErrorNotification,
  setMessageNotification,
} from "../../redux/notification/slice"
import {
  maxWidthMobile,
  maxWidthTablet,
  minWidthLaptop,
  RoutesOptions,
} from "../../utils/constants"
import { getGenericErrorMessage } from "../../utils/error-messages"
import { useStyles } from "./trial-over-page.styles"
import {
  callbackParams,
  customization,
  paymentParms,
} from "../../utils/payment"

export const TrialOverPage = () => {
  const { classes, theme } = useStyles()
  const navigate = useNavigate()
  const isTrial = sessionStorage.getItem("isTrial")
  const isTablet = useMediaQuery(maxWidthTablet)
  const isMobile = useMediaQuery(maxWidthMobile)
  const isLaptopXl = useMediaQuery(minWidthLaptop)
  const [
    paymentModalOpened,
    { open: openPaymentModal, close: closePaymentModal },
  ] = useDisclosure(false)
  const { data, refetch, isRefetching, isLoading } = useGetUser()
  const dispatch = useDispatch()
  useEffect(() => {
    refetch()
  }, [])
  const userDataResponse = data?.data.response

  const userPaymentSuccessCallback = (data: any) => {
    setTimeout(() => {
      closePaymentModal()
      refetch()
      dispatch(setMessageNotification(data.message))
    }, 200)
    navigate(RoutesOptions.CLIENTS)
  }
  const userPaymentErrorCallback = (err: any) => {
    refetch()
    dispatch(
      setErrorNotification(
        getGenericErrorMessage(
          "Daca problema persistă contactează echipa de suport!"
        )
      )
    )
  }
  const { mutate } = usePostUserPayment(
    (data) => userPaymentSuccessCallback(data),
    (err) => userPaymentErrorCallback(err)
  )
  const triggerPayment = () => {
    openPaymentModal()
    setTimeout(() => {
      MyPOSEmbedded.createPayment(
        "embeddedCheckout",
        paymentParms(userDataResponse?.usedAffiliateCode),
        callbackParams(userDataResponse, userPaymentErrorCallback, mutate),
        customization
      )
    }, 200)
  }

  return (
    <Container h="100vh" m={0} maw="100vw">
      <LoadingOverlay visible={isLoading || isRefetching} />
      <Flex
        direction={"column"}
        align="center"
        h={"100%"}
        justify={"space-between"}
      >
        <Image
          src={"/Logo_Topo_CRM.png"}
          alt="Logo Topo"
          height="auto"
          width="auto"
          className={classes.hoverImage}
          onClick={() => {
            navigate(RoutesOptions.LOGIN)
            sessionStorage.clear()
          }}
        />

        <Paper
          radius={"xl"}
          maw={isLaptopXl ? "50%" : isMobile ? "95%" : "75%"}
          w="100%"
          bg={"#ffffffc4"}
        >
          <Flex align="center" gap={0} h="100%" w={isTablet ? "100%" : "auto"}>
            <Box w="100%" display={isTablet ? "none" : "block"}>
              <Image
                pl="10%"
                width={isLaptopXl ? "400px" : "80%"}
                src="/subscriber.svg"
                fit="contain"
                mt="xl"
                alt="Subscriber"
              />
            </Box>
            <Flex
              py={isTablet ? "4rem" : "0"}
              direction={"column"}
              align={"center"}
              justify="center"
              gap={20}
              w="100%"
            >
              <Stack spacing="1rem" align="center" w="100%" my="xl">
                {isTablet ? (
                  <Image
                    src="/subscription.png"
                    width={"6rem"}
                    height={"6rem"}
                    alt="Subscription"
                  />
                ) : null}
                <Title
                  order={isMobile ? 3 : 2}
                  c={theme.colors.brand[9]}
                  align="center"
                  w={"85%"}
                  mb={"lg"}
                >
                  Vă mulțumim pentru că ați explorat serviciile noastre!
                </Title>
                {isTrial === "true" ? (
                  <Text w={"80%"} align="justify">
                    Perioada de probă s-a încheiat! Ai simțit avantajele noastre
                    exclusive, acum e timpul să faci pasul următor.
                  </Text>
                ) : (
                  <Text w={"80%"} align="justify">
                    Din păcate nu ați achitat la timp abonamentul, iar contul a
                    fost suspendat până la plată.
                  </Text>
                )}
                {isTrial === "true" ? (
                  <Text w={"80%"} align="justify">
                    Abonează-te acum pentru a accesa în continuare
                    funcționalitățile și a experimenta tot ce avem de oferit.
                  </Text>
                ) : (
                  <Text w={"80%"} align="justify">
                    Plătește acum pentru a accesa în continuare
                    funcționalitățile și a experimenta tot ce avem de oferit.
                  </Text>
                )}
                <Text w={"80%"} align="justify">
                  Pentru mai multe detalii poți accesa{" "}
                  <Link to="https://topo-crm.ro/" target="_blank">
                    topo-crm.ro
                  </Link>
                </Text>

                <Button
                  variant="light"
                  radius={"xl"}
                  w={"80%"}
                  my={"sm"}
                  onClick={triggerPayment}
                >
                  {isTrial === "true" ? "Abonează-te" : "Plătește"}
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </Paper>

        <Title order={3} c={theme.colors.brand[8]}>
          © evosa
        </Title>
      </Flex>
      <Modal
        xOffset={0}
        size={isMobile ? "calc(100vw - 2rem)" : "lg"}
        opened={paymentModalOpened}
        onClose={closePaymentModal}
        centered
        withCloseButton={true}
        padding="xl"
      >
        <div id="embeddedCheckout"></div>
      </Modal>
    </Container>
  )
}
